import React, { PureComponent } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'

import classnames from 'classnames'
import { withPreview } from 'gatsby-source-prismic-graphql'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { createRichTextSerializer } from '../utils/prismic'

import arrow from '../images/cta-arrow.svg'
import PageHeader from '../components/PageHeader'

const query = graphql`
  query {
    prismic {
      page: programs_page(uid: "programs", lang: "en-us") {
        title
        header_left
        description_right
        contact_us_label
      }
    }

    initiatives1Image: file(relativePath: { eq: "initiatives-1-new.jpg" }) {
      childImageSharp {
        fixed(width: 293) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    initiativesMobileImage: file(
      relativePath: { eq: "initiatives-1-new.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    initiatives2Image: file(relativePath: { eq: "initiatives-2-new.jpg" }) {
      childImageSharp {
        fixed(width: 293) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    initiatives3Image: file(relativePath: { eq: "initiatives-3-new.jpg" }) {
      childImageSharp {
        fixed(width: 293) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const descriptionSerializer = createRichTextSerializer({
  paragraphClassname: ({ index }) =>
    classnames('font-serif leading-normal text-lg', {
      'mt-4': index > 0,
    }),
})

export default class ComingSoonPage extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={query}
        render={withPreview(data => {
          const { page } = data.prismic
          const queryData = data
          return (
            <Layout>
              <SEO title={page.title} keywords={[`longevity`]} />

              <PageHeader titles={[page.title]} />

              <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto font-serif text-blue-dianne">
                <div className="lg:flex mt-24">
                  <div className="lg:w-1/2 lg:pr-4">
                    <h2 className="text-lg lg:text-xl font-normal">
                      {page.header_left}
                    </h2>
                  </div>
                  <div className="lg:w-1/2 lg:pl-4 mt-8 lg:mt-0">
                    {RichText.render(
                      page.description_right,
                      undefined,
                      descriptionSerializer
                    )}
                    <div className="flex mt-12">
                      <Link
                        to="/contact"
                        className="ml-auto font-serif text-lg text-blue-dianne bg-selago px-8 py-5 flex items-center no-underline"
                      >
                        <span className="cta-ie11-fix">
                          {page.contact_us_label}
                        </span>{' '}
                        <img src={arrow} alt="" className="ml-8 h-6" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="hidden lg:grid container mx-auto initiatives-container my-32">
                  <div className="initiatives-header flex items-center">
                    <h1 className="font-serif text-headline text-blue-dianne font-normal m-8 ml-0">
                      <p>&nbsp;</p>
                    </h1>
                  </div>
                  <div className="initiatives-photo1 lg:flex lg:items-end">
                    <Img
                      fixed={queryData.initiatives1Image.childImageSharp.fixed}
                    />
                  </div>
                  <div className="initiatives-photo3">
                    <Img
                      fixed={queryData.initiatives2Image.childImageSharp.fixed}
                    />
                  </div>
                  <div className="initiatives-photo4">
                    <Img
                      fixed={queryData.initiatives3Image.childImageSharp.fixed}
                    />
                  </div>
                  <div className="initiatives-description flex items-center">
                    <p className="font-sans text-blue-dianne mr-8 leading-normal">
                      &nbsp;
                    </p>
                  </div>
                  <div className="initiatives-cta">
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </Layout>
          )
        }, query)}
      />
    )
  }
}
